import OnDemandThankYouPage from "components/thank-you/on-demand-video/OnDemandThankYouPage";
import React from "react";

export default function ThankYouPageRoot() {
  return (
    <OnDemandThankYouPage
      videoId="r95t3ar4q1"
      title="How to Develop Intelligent Campus Security Strategies in Today’s World"
      description="Learn how schools across the nation are leveraging modern technology solutions to develop intelligent campus security strategies."
    />
  );
}
